body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  height: 100%;
  overflow: hidden;
  width: 100%;
}

html, body {
  background: #f2f2f2!important;
  height: 100%;
  overflow: hidden;
  width: 100%;
  box-sizing: border-box;
}

